jQuery(function () {
    var myOffside = offside("#mobileMenu", {
        slidingElementsSelector: "body",
        buttonsSelector: ".menu-toggle, .offcanvas-close, .offcanvas-overlay",
        beforeOpen: function () {
            $(".offcanvas-overlay").fadeIn();
        },
        beforeClose: function () {
            $(".offcanvas-overlay").fadeOut();
        },
    });

    $(".expand-toggle").on("click", function (e) {
        e.preventDefault();
        $(this).prev().toggleClass("show");
        $(this).find("strong").toggleText("Xem thêm", "Rút gọn");
        $(this).find("span").toggleClass("ri-arrow-down-s-line ri-arrow-up-s-line");
    });

    $(".product-detail-info .expand-content").each(function () {
        if ($(this).height() < 530) {
            $(this).next().hide();
        } else {
            $(this).next().show();
        }
    });

    collapseComponent()
    productFilter()
    productDetailSlide()
    spinInput()
    dealCountdown()

});

$.fn.extend({
    toggleText: function (a, b) {
        return this.text(this.text() == b ? a : b);
    },
});

function parseDate(date) {
    var dateInit = date;
    var datearray = dateInit.split(" ");
    var dateNeedFormat = datearray[0];
    var dateNeedFormatArray = dateNeedFormat.split(/\//);
    var dateAfterFormat = [
        dateNeedFormatArray[1],
        dateNeedFormatArray[0],
        dateNeedFormatArray[2],
    ].join("/");
    return [dateAfterFormat, datearray[1]].join(" ");
}

const logoMapping = new MappingListener({
    selector: ".logo",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".menu-toggle",
    desktopMethod: "insertBefore",
    breakpoint: 1024.98,
}).watch();

const topHeaderUtilMapping = new MappingListener({
    selector: ".top-header-util",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".top-header-wrap",
    desktopMethod: "appendTo",
    breakpoint: 1024.98,
}).watch();

const hotlineMapping = new MappingListener({
    selector: ".hotline-wrapper",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".search-wrapper",
    desktopMethod: "insertAfter",
    breakpoint: 1024.98,
}).watch();

const bottomHeaderMapping = new MappingListener({
    selector: ".bottom-header",
    mobileWrapper: ".offcanvas-wrap",
    mobileMethod: "appendTo",
    desktopWrapper: ".middle-header",
    desktopMethod: "insertAfter",
    breakpoint: 1024.98,
}).watch();

function collapseComponent() {
    $(document).on("click", ".collapse-trigger", function () {
        $(this).find("span").toggleClass("ri-arrow-down-s-line ri-arrow-up-s-line");
        $(this).next().slideToggle();
        $(this).toggleClass("active");
        $(this).parent().toggleClass("active");
        return false;
    });
}

var newsSlider = new Swiper('.news-slider .swiper-container', {
    slidesPerView: 3,
    spaceBetween: 30,
    navigation: {
        prevEl: '.news-slider .swiper-prev',
        nextEl: '.news-slider .swiper-next'
    },
    breakpoints: {
        1280: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 2.25,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 1.25,
            spaceBetween: 15,
        }
    }
})

var partnerSlider = new Swiper(".partner-slider .swiper-container", {
    slidesPerView: 6,
    spaceBetween: 15,
    autoplay: {
        delay: 2500,
    },
    navigation: {
        prevEl: '.partner-slider .swiper-prev',
        nextEl: '.partner-slider .swiper-next'
    },
    breakpoints: {
        1280: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3,
        },
        576: {
            slidesPerView: 2,
        }
    }
})

var shipPlaceSlider = new Swiper(".ship-place-slider .swiper-container", {
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
        delay: 2500,
    },
    navigation: {
        prevEl: '.ship-place-slider .swiper-prev',
        nextEl: '.ship-place-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 2,
            spaceBetween: 15
        },
        576: {
            slidesPerView: 1.5,
        }
    }
})

var orderStuffSlider = new Swiper(".order-stuff-slider .swiper-container", {
    slidesPerView: 4,
    spaceBetween: 20,
    navigation: {
        prevEl: '.order-stuff-slider .swiper-prev',
        nextEl: '.order-stuff-slider .swiper-next'
    },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 15
        },
        768: {
            slidesPerView: 2.25,
        },
        576: {
            slidesPerView: 1.5,
        }
    }
})

function productFilter() {
    var triggerBtn = $(".product-filter-title");
    var content = $(".product-filter-body");

    $(document).on("click", ".product-filter-title", function () {
        $(this).parent().siblings().find(".product-filter-body").slideUp();
        $(this)
            .parent()
            .siblings()
            .find(".product-filter-title")
            .removeClass("active");
        if (!$(this).hasClass("active")) {
            $(this).addClass("active");
            $(this).next().slideDown();
        } else {
            $(this).removeClass("active");
            $(this).next().slideUp();
        }
    });

    $(".filter-close").on("click", function () {
        $(".product-filter-wrap").slideUp();
        $("body").removeClass("overflow-hidden");
    });

    $(".filter-view-more").on("click", function (e) {
        e.preventDefault();
        var $parent = $(this).parents(".product-filter-group");
        $parent.find("ul li:nth-child(n + 5)").slideToggle();
        $(this)
            .find("em")
            .toggleClass("ri-arrow-down-s-fill ri-arrow-up-s-fill");
        $(this).toggleText("+ Xem tất cả", "- Rút Gọn");
    });
}

function productDetailSlide() {

    var productDetailThumbnail = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            slidesPerView: 4,
            spaceBetween: 20,
            observer: true,
            observeParents: true,
            scrollbar: {
                el: ".product-detail-thumbnail .swiper-scrollbar",
                draggable: true,
            },
            breakpoints: {
                576: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
            },
        }
    );
    
    var productDetailImages = new Swiper(
        ".product-detail-images .swiper-container",
        {
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productDetailThumbnail,
            }
        }
    );
}

function dealCountdown() {
    $(".deal-countdown").each(function () {
        var date = $(this).data("countdown");
        var formatDate = parseDate(date);
        $(this).countdown(formatDate, function (event) {
            $(this).html(event.strftime("%-D ngày %H:%M:%S"))
        });
    });
}

var productSlide = new Swiper(".product-slider .swiper-container", {
    slidesPerView: 5,
    navigation: {
        prevEl: ".product-slider .swiper-prev",
        nextEl: ".product-slider .swiper-next",
    },
    breakpoints: {
        1024: {
            slidesPerView: 4,
        },
        768: {
            slidesPerView: 3.25,
        },
        576: {
            slidesPerView: 2.25,
        }
    },
});

var flashSaleSlide = new Swiper(".flash-deal-slider .swiper-container", {
    slidesPerView: 5,
    spaceBetween: 7,
    navigation: {
        prevEl: ".flash-deal-slider .swiper-prev",
        nextEl: ".flash-deal-slider .swiper-next",
    },
    breakpoints: {
        1280: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 2.25,
        },
        576: {
            slidesPerView: 1.5,
        }
    },
});

var bigBannerSlide = new Swiper(".big-banner .swiper-container", {
    slidesPerView: 1,
    pagination: {
        el: '.big-banner .swiper-pagination',
        clickable: true
    }
});

function spinInput() {
    $(".spin-btn").click(function () {
        var $button = $(this);
        var type = $(this).data("spin");
        var oldValue = $button.parent().find("input").val();

        if (type == "inc") {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }
        $button.parent().find("input").val(newVal);
        //   AjaxCart.updatecart();
    });
}
